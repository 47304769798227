export type PlatformRate = {
  amount: number
  currency: string
  freeDays: number
  interval: 'monthly' | 'yearly'
  chargebeeSubscription: string
  chargebeeSubscriptionCoupon: string
}

export type ShippingCosts = {
  group: number | number[] | string
  values: {
    zone: string
    price: number
  }[]
}

export type Shipping = {
  countries: { code: string; zone: string }[]
  costs: {
    default: ShippingCosts[]
    express: ShippingCosts[] | undefined
  }
}

export type Region = {
  id: string
  currency: string
  defaultLanguage: string
  languages: string[]
  units: 'imperial' | 'metric'
  payments: string[]
  shipping: Shipping
  customerSupport: {
    to: string
    phone: {
      de?: string
      en?: string
      fr?: string
      nl?: string
    }
  }
  platform: {
    monthly: PlatformRate
    yearly: PlatformRate
  }
  consulting: {
    consultation: { price: number; currency: string; booking: string }
    weekdays: string
    hours: string[][]
    city: string
    mapsLink: string
  }
}

export const CSP = Object.entries({
  'default-src': ["'self'", "'unsafe-inline'"],
  'media-src': ["'self'", '*.mux.com', 'blob:', 'static.zdassets.com'],
  'img-src': [
    "'self'",
    'bellicon-development-static.fra1.digitaloceanspaces.com',
    'http://dev.bellicon.com',
    '*.bellicon.com',
    '*.experify.io',
    'fonts.gstatic.com',
    '*.shopify.com',
    '*.experify.io',
    'fonts.gstatic.com',
    '*.mux.com',
    '*.google-analytics.com',
    '*.googletagmanager.com',
    'ssl.gstatic.com',
    'www.gstatic.com',
    'www.facebook.com',
    's.pinimg.com',
    'ct.pinterest.com',
    '*.outbrain.com',
    '*.bing.com',
    '*.googleadservices.com',
    '*.googlesyndication.com',
    '*.google.com',
    '*.google.ad',
    '*.google.ae',
    '*.google.com.af',
    '*.google.com.ag',
    '*.google.al',
    '*.google.am',
    '*.google.co.ao',
    '*.google.com.ar',
    '*.google.as',
    '*.google.at',
    '*.google.com.au',
    '*.google.az',
    '*.google.ba',
    '*.google.com.bd',
    '*.google.be',
    '*.google.bf',
    '*.google.bg',
    '*.google.com.bh',
    '*.google.bi',
    '*.google.bj',
    '*.google.com.bn',
    '*.google.com.bo',
    '*.google.com.br',
    '*.google.bs',
    '*.google.bt',
    '*.google.co.bw',
    '*.google.by',
    '*.google.com.bz',
    '*.google.ca',
    '*.google.cd',
    '*.google.cf',
    '*.google.cg',
    '*.google.ch',
    '*.google.ci',
    '*.google.co.ck',
    '*.google.cl',
    '*.google.cm',
    '*.google.cn',
    '*.google.com.co',
    '*.google.co.cr',
    '*.google.com.cu',
    '*.google.cv',
    '*.google.com.cy',
    '*.google.cz',
    '*.google.de',
    '*.google.dj',
    '*.google.dk',
    '*.google.dm',
    '*.google.com.do',
    '*.google.dz',
    '*.google.com.ec',
    '*.google.ee',
    '*.google.com.eg',
    '*.google.es',
    '*.google.com.et',
    '*.google.fi',
    '*.google.com.fj',
    '*.google.fm',
    '*.google.fr',
    '*.google.ga',
    '*.google.ge',
    '*.google.gg',
    '*.google.com.gh',
    '*.google.com.gi',
    '*.google.gl',
    '*.google.gm',
    '*.google.gr',
    '*.google.com.gt',
    '*.google.gy',
    '*.google.com.hk',
    '*.google.hn',
    '*.google.hr',
    '*.google.ht',
    '*.google.hu',
    '*.google.co.id',
    '*.google.ie',
    '*.google.co.il',
    '*.google.im',
    '*.google.co.in',
    '*.google.iq',
    '*.google.is',
    '*.google.it',
    '*.google.je',
    '*.google.com.jm',
    '*.google.jo',
    '*.google.co.jp',
    '*.google.co.ke',
    '*.google.com.kh',
    '*.google.ki',
    '*.google.kg',
    '*.google.co.kr',
    '*.google.com.kw',
    '*.google.kz',
    '*.google.la',
    '*.google.com.lb',
    '*.google.li',
    '*.google.lk',
    '*.google.co.ls',
    '*.google.lt',
    '*.google.lu',
    '*.google.lv',
    '*.google.com.ly',
    '*.google.co.ma',
    '*.google.md',
    '*.google.me',
    '*.google.mg',
    '*.google.mk',
    '*.google.ml',
    '*.google.com.mm',
    '*.google.mn',
    '*.google.com.mt',
    '*.google.mu',
    '*.google.mv',
    '*.google.mw',
    '*.google.com.mx',
    '*.google.com.my',
    '*.google.co.mz',
    '*.google.com.na',
    '*.google.com.ng',
    '*.google.com.ni',
    '*.google.ne',
    '*.google.nl',
    '*.google.no',
    '*.google.com.np',
    '*.google.nr',
    '*.google.nu',
    '*.google.co.nz',
    '*.google.com.om',
    '*.google.com.pa',
    '*.google.com.pe',
    '*.google.com.pg',
    '*.google.com.ph',
    '*.google.com.pk',
    '*.google.pl',
    '*.google.pn',
    '*.google.com.pr',
    '*.google.ps',
    '*.google.pt',
    '*.google.com.py',
    '*.google.com.qa',
    '*.google.ro',
    '*.google.ru',
    '*.google.rw',
    '*.google.com.sa',
    '*.google.com.sb',
    '*.google.sc',
    '*.google.se',
    '*.google.com.sg',
    '*.google.sh',
    '*.google.si',
    '*.google.sk',
    '*.google.com.sl',
    '*.google.sn',
    '*.google.so',
    '*.google.sm',
    '*.google.sr',
    '*.google.st',
    '*.google.com.sv',
    '*.google.td',
    '*.google.tg',
    '*.google.co.th',
    '*.google.com.tj',
    '*.google.tl',
    '*.google.tm',
    '*.google.tn',
    '*.google.to',
    '*.google.com.tr',
    '*.google.tt',
    '*.google.com.tw',
    '*.google.co.tz',
    '*.google.com.ua',
    '*.google.co.ug',
    '*.google.co.uk',
    '*.google.com.uy',
    '*.google.co.uz',
    '*.google.com.vc',
    '*.google.co.ve',
    '*.google.co.vi',
    '*.google.com.vn',
    '*.google.vu',
    '*.google.ws',
    '*.google.rs',
    '*.google.co.za',
    '*.google.co.zm',
    '*.google.co.zw',
    '*.google.cat',
    '*.doubleclick.net',
    '*.cookiebot.com',
    'data:',
    'widgets.trustedshops.com',
    '*.tracify.ai',
    'events.attentivemobile.com',
  ],
  'font-src': [
    "'self'",
    'fonts.gstatic.com',
    '*.tracify.ai',
    '*.experify.io',
    'data:',
  ],
  'script-src': [
    "'self'",
    'blob:',
    '*.bellicon.com',
    "'unsafe-inline'",
    '*.tracify.ai',
    '*.experify.io',
    "'unsafe-eval'",
    '*.cookiebot.com',
    '*.googletagmanager.com',
    'tagmanager.google.com',
    '*.google.com',
    '*.google.ad',
    '*.google.ae',
    '*.google.com.af',
    '*.google.com.ag',
    '*.google.al',
    '*.google.am',
    '*.google.co.ao',
    '*.google.com.ar',
    '*.google.as',
    '*.google.at',
    '*.google.com.au',
    '*.google.az',
    '*.google.ba',
    '*.google.com.bd',
    '*.google.be',
    '*.google.bf',
    '*.google.bg',
    '*.google.com.bh',
    '*.google.bi',
    '*.google.bj',
    '*.google.com.bn',
    '*.google.com.bo',
    '*.google.com.br',
    '*.google.bs',
    '*.google.bt',
    '*.google.co.bw',
    '*.google.by',
    '*.google.com.bz',
    '*.google.ca',
    '*.google.cd',
    '*.google.cf',
    '*.google.cg',
    '*.google.ch',
    '*.google.ci',
    '*.google.co.ck',
    '*.google.cl',
    '*.google.cm',
    '*.google.cn',
    '*.google.com.co',
    '*.google.co.cr',
    '*.google.com.cu',
    '*.google.cv',
    '*.google.com.cy',
    '*.google.cz',
    '*.google.de',
    '*.google.dj',
    '*.google.dk',
    '*.google.dm',
    '*.google.com.do',
    '*.google.dz',
    '*.google.com.ec',
    '*.google.ee',
    '*.google.com.eg',
    '*.google.es',
    '*.google.com.et',
    '*.google.fi',
    '*.google.com.fj',
    '*.google.fm',
    '*.google.fr',
    '*.google.ga',
    '*.google.ge',
    '*.google.gg',
    '*.google.com.gh',
    '*.google.com.gi',
    '*.google.gl',
    '*.google.gm',
    '*.google.gr',
    '*.google.com.gt',
    '*.google.gy',
    '*.google.com.hk',
    '*.google.hn',
    '*.google.hr',
    '*.google.ht',
    '*.google.hu',
    '*.google.co.id',
    '*.google.ie',
    '*.google.co.il',
    '*.google.im',
    '*.google.co.in',
    '*.google.iq',
    '*.google.is',
    '*.google.it',
    '*.google.je',
    '*.google.com.jm',
    '*.google.jo',
    '*.google.co.jp',
    '*.google.co.ke',
    '*.google.com.kh',
    '*.google.ki',
    '*.google.kg',
    '*.google.co.kr',
    '*.google.com.kw',
    '*.google.kz',
    '*.google.la',
    '*.google.com.lb',
    '*.google.li',
    '*.google.lk',
    '*.google.co.ls',
    '*.google.lt',
    '*.google.lu',
    '*.google.lv',
    '*.google.com.ly',
    '*.google.co.ma',
    '*.google.md',
    '*.google.me',
    '*.google.mg',
    '*.google.mk',
    '*.google.ml',
    '*.google.com.mm',
    '*.google.mn',
    '*.google.com.mt',
    '*.google.mu',
    '*.google.mv',
    '*.google.mw',
    '*.google.com.mx',
    '*.google.com.my',
    '*.google.co.mz',
    '*.google.com.na',
    '*.google.com.ng',
    '*.google.com.ni',
    '*.google.ne',
    '*.google.nl',
    '*.google.no',
    '*.google.com.np',
    '*.google.nr',
    '*.google.nu',
    '*.google.co.nz',
    '*.google.com.om',
    '*.google.com.pa',
    '*.google.com.pe',
    '*.google.com.pg',
    '*.google.com.ph',
    '*.google.com.pk',
    '*.google.pl',
    '*.google.pn',
    '*.google.com.pr',
    '*.google.ps',
    '*.google.pt',
    '*.google.com.py',
    '*.google.com.qa',
    '*.google.ro',
    '*.google.ru',
    '*.google.rw',
    '*.google.com.sa',
    '*.google.com.sb',
    '*.google.sc',
    '*.google.se',
    '*.google.com.sg',
    '*.google.sh',
    '*.google.si',
    '*.google.sk',
    '*.google.com.sl',
    '*.google.sn',
    '*.google.so',
    '*.google.sm',
    '*.google.sr',
    '*.google.st',
    '*.google.com.sv',
    '*.google.td',
    '*.google.tg',
    '*.google.co.th',
    '*.google.com.tj',
    '*.google.tl',
    '*.google.tm',
    '*.google.tn',
    '*.google.to',
    '*.google.com.tr',
    '*.google.tt',
    '*.google.com.tw',
    '*.google.co.tz',
    '*.google.com.ua',
    '*.google.co.ug',
    '*.google.co.uk',
    '*.google.com.uy',
    '*.google.co.uz',
    '*.google.com.vc',
    '*.google.co.ve',
    '*.google.co.vi',
    '*.google.com.vn',
    '*.google.vu',
    '*.google.ws',
    '*.google.rs',
    '*.google.co.za',
    '*.google.co.zm',
    '*.google.co.zw',
    '*.google.cat',
    'www.facebook.com',
    'connect.facebook.net',
    's.pinimg.com',
    'ct.pinterest.com',
    '*.outbrain.com',
    '*.bing.com',
    '*.googleadservices.com',
    '*.googlesyndication.com',
    '*.cloudflareinsights.com',
    '*.stape.io',
    '*.cloudflare.com',
    'assets.zendesk.com',
    'static.zdassets.com',
    '*.hotjar.com',
    'widget-mediator.zopim.com',
    '*.doubleclick.net',
    'chimpstatic.com',
    'widgets.trustedshops.com',
    'www.gstatic.com',
    '*.attentivemobile.com',
    '*.attn.tv',
    '*.postaffiliatepro.com',
  ],
  'style-src': [
    "'self'",
    'https:',
    "'unsafe-inline'",
    'tagmanager.google.com',
    'fonts.googleapis.com',
    '*.tracify.ai',
  ],
  'connect-src': [
    "'self'",
    '*',
    '*.bellicon.com:8010',
    '*.bellicon.com',
    process.env.CMS_ENDPOINT,
    process.env.API_EU_ENDPOINT,
    process.env.API_US_ENDPOINT,
    process.env.IDP_ENDPOINT,
    '*.cookiebot.com',
    '*.sentry.io',
    '*.myshopify.com',
    '*.mux.com',
    '*.litix.io',
    '*.google-analytics.com',
    '*.googlesyndication.com',
    '*.analytics.google.com',
    '*.googletagmanager.com',
    '*.google.com',
    '*.google.ad',
    '*.google.ae',
    '*.google.com.af',
    '*.google.com.ag',
    '*.google.al',
    '*.google.am',
    '*.google.co.ao',
    '*.google.com.ar',
    '*.google.as',
    '*.google.at',
    '*.google.com.au',
    '*.google.az',
    '*.google.ba',
    '*.google.com.bd',
    '*.google.be',
    '*.google.bf',
    '*.google.bg',
    '*.google.com.bh',
    '*.google.bi',
    '*.google.bj',
    '*.google.com.bn',
    '*.google.com.bo',
    '*.google.com.br',
    '*.google.bs',
    '*.google.bt',
    '*.google.co.bw',
    '*.google.by',
    '*.google.com.bz',
    '*.google.ca',
    '*.google.cd',
    '*.google.cf',
    '*.google.cg',
    '*.google.ch',
    '*.google.ci',
    '*.google.co.ck',
    '*.google.cl',
    '*.google.cm',
    '*.google.cn',
    '*.google.com.co',
    '*.google.co.cr',
    '*.google.com.cu',
    '*.google.cv',
    '*.google.com.cy',
    '*.google.cz',
    '*.google.de',
    '*.google.dj',
    '*.google.dk',
    '*.google.dm',
    '*.google.com.do',
    '*.google.dz',
    '*.google.com.ec',
    '*.google.ee',
    '*.google.com.eg',
    '*.google.es',
    '*.google.com.et',
    '*.google.fi',
    '*.google.com.fj',
    '*.google.fm',
    '*.google.fr',
    '*.google.ga',
    '*.google.ge',
    '*.google.gg',
    '*.google.com.gh',
    '*.google.com.gi',
    '*.google.gl',
    '*.google.gm',
    '*.google.gr',
    '*.google.com.gt',
    '*.google.gy',
    '*.google.com.hk',
    '*.google.hn',
    '*.google.hr',
    '*.google.ht',
    '*.google.hu',
    '*.google.co.id',
    '*.google.ie',
    '*.google.co.il',
    '*.google.im',
    '*.google.co.in',
    '*.google.iq',
    '*.google.is',
    '*.google.it',
    '*.google.je',
    '*.google.com.jm',
    '*.google.jo',
    '*.google.co.jp',
    '*.google.co.ke',
    '*.google.com.kh',
    '*.google.ki',
    '*.google.kg',
    '*.google.co.kr',
    '*.google.com.kw',
    '*.google.kz',
    '*.google.la',
    '*.google.com.lb',
    '*.google.li',
    '*.google.lk',
    '*.google.co.ls',
    '*.google.lt',
    '*.google.lu',
    '*.google.lv',
    '*.google.com.ly',
    '*.google.co.ma',
    '*.google.md',
    '*.google.me',
    '*.google.mg',
    '*.google.mk',
    '*.google.ml',
    '*.google.com.mm',
    '*.google.mn',
    '*.google.com.mt',
    '*.google.mu',
    '*.google.mv',
    '*.google.mw',
    '*.google.com.mx',
    '*.google.com.my',
    '*.google.co.mz',
    '*.google.com.na',
    '*.google.com.ng',
    '*.google.com.ni',
    '*.google.ne',
    '*.google.nl',
    '*.google.no',
    '*.google.com.np',
    '*.google.nr',
    '*.google.nu',
    '*.google.co.nz',
    '*.google.com.om',
    '*.google.com.pa',
    '*.google.com.pe',
    '*.google.com.pg',
    '*.google.com.ph',
    '*.google.com.pk',
    '*.google.pl',
    '*.google.pn',
    '*.google.com.pr',
    '*.google.ps',
    '*.google.pt',
    '*.google.com.py',
    '*.google.com.qa',
    '*.google.ro',
    '*.google.ru',
    '*.google.rw',
    '*.google.com.sa',
    '*.google.com.sb',
    '*.google.sc',
    '*.google.se',
    '*.google.com.sg',
    '*.google.sh',
    '*.google.si',
    '*.google.sk',
    '*.google.com.sl',
    '*.google.sn',
    '*.google.so',
    '*.google.sm',
    '*.google.sr',
    '*.google.st',
    '*.google.com.sv',
    '*.google.td',
    '*.google.tg',
    '*.google.co.th',
    '*.google.com.tj',
    '*.google.tl',
    '*.google.tm',
    '*.google.tn',
    '*.google.to',
    '*.google.com.tr',
    '*.google.tt',
    '*.google.com.tw',
    '*.google.co.tz',
    '*.google.com.ua',
    '*.google.co.ug',
    '*.google.co.uk',
    '*.google.com.uy',
    '*.google.co.uz',
    '*.google.com.vc',
    '*.google.co.ve',
    '*.google.co.vi',
    '*.google.com.vn',
    '*.google.vu',
    '*.google.ws',
    '*.google.rs',
    '*.google.co.za',
    '*.google.co.zm',
    '*.google.co.zw',
    '*.google.cat',
    'bellicon.zendesk.com',
    'belliconusa.zendesk.com',
    'belliconsupport.zendesk.com',
    'static.zdassets.com',
    '*.zendesk.com',
    '*.zdassets.com',
    'wss://widget-mediator.zopim.com',
    's.pinimg.com',
    'ct.pinterest.com',
    '*.doubleclick.net',
    '*.bing.com',
    `ws://${process.env.DOMAIN?.split('://')[1]}`,
    '*.hotjar.io',
    '*.hotjar.com',
    'wss://*.hotjar.com',
    'www.facebook.com',
    'widgets.trustedshops.com',
    '*.tracify.ai',
    'wss://*.tracify.ai',
    '*.attentivemobile.com',
    '*.attn.tv',
    '*.outbrain.com',
  ],
  'frame-src': [
    "'self'",
    '*.experify.io',
    '*.google.com',
    '*.google.ad',
    '*.google.ae',
    '*.google.com.af',
    '*.google.com.ag',
    '*.google.al',
    '*.google.am',
    '*.google.co.ao',
    '*.google.com.ar',
    '*.google.as',
    '*.google.at',
    '*.google.com.au',
    '*.google.az',
    '*.google.ba',
    '*.google.com.bd',
    '*.google.be',
    '*.google.bf',
    '*.google.bg',
    '*.google.com.bh',
    '*.google.bi',
    '*.google.bj',
    '*.google.com.bn',
    '*.google.com.bo',
    '*.google.com.br',
    '*.google.bs',
    '*.google.bt',
    '*.google.co.bw',
    '*.google.by',
    '*.google.com.bz',
    '*.google.ca',
    '*.google.cd',
    '*.google.cf',
    '*.google.cg',
    '*.google.ch',
    '*.google.ci',
    '*.google.co.ck',
    '*.google.cl',
    '*.google.cm',
    '*.google.cn',
    '*.google.com.co',
    '*.google.co.cr',
    '*.google.com.cu',
    '*.google.cv',
    '*.google.com.cy',
    '*.google.cz',
    '*.google.de',
    '*.google.dj',
    '*.google.dk',
    '*.google.dm',
    '*.google.com.do',
    '*.google.dz',
    '*.google.com.ec',
    '*.google.ee',
    '*.google.com.eg',
    '*.google.es',
    '*.google.com.et',
    '*.google.fi',
    '*.google.com.fj',
    '*.google.fm',
    '*.google.fr',
    '*.google.ga',
    '*.google.ge',
    '*.google.gg',
    '*.google.com.gh',
    '*.google.com.gi',
    '*.google.gl',
    '*.google.gm',
    '*.google.gr',
    '*.google.com.gt',
    '*.google.gy',
    '*.google.com.hk',
    '*.google.hn',
    '*.google.hr',
    '*.google.ht',
    '*.google.hu',
    '*.google.co.id',
    '*.google.ie',
    '*.google.co.il',
    '*.google.im',
    '*.google.co.in',
    '*.google.iq',
    '*.google.is',
    '*.google.it',
    '*.google.je',
    '*.google.com.jm',
    '*.google.jo',
    '*.google.co.jp',
    '*.google.co.ke',
    '*.google.com.kh',
    '*.google.ki',
    '*.google.kg',
    '*.google.co.kr',
    '*.google.com.kw',
    '*.google.kz',
    '*.google.la',
    '*.google.com.lb',
    '*.google.li',
    '*.google.lk',
    '*.google.co.ls',
    '*.google.lt',
    '*.google.lu',
    '*.google.lv',
    '*.google.com.ly',
    '*.google.co.ma',
    '*.google.md',
    '*.google.me',
    '*.google.mg',
    '*.google.mk',
    '*.google.ml',
    '*.google.com.mm',
    '*.google.mn',
    '*.google.com.mt',
    '*.google.mu',
    '*.google.mv',
    '*.google.mw',
    '*.google.com.mx',
    '*.google.com.my',
    '*.google.co.mz',
    '*.google.com.na',
    '*.google.com.ng',
    '*.google.com.ni',
    '*.google.ne',
    '*.google.nl',
    '*.google.no',
    '*.google.com.np',
    '*.google.nr',
    '*.google.nu',
    '*.google.co.nz',
    '*.google.com.om',
    '*.google.com.pa',
    '*.google.com.pe',
    '*.google.com.pg',
    '*.google.com.ph',
    '*.google.com.pk',
    '*.google.pl',
    '*.google.pn',
    '*.google.com.pr',
    '*.google.ps',
    '*.google.pt',
    '*.google.com.py',
    '*.google.com.qa',
    '*.google.ro',
    '*.google.ru',
    '*.google.rw',
    '*.google.com.sa',
    '*.google.com.sb',
    '*.google.sc',
    '*.google.se',
    '*.google.com.sg',
    '*.google.sh',
    '*.google.si',
    '*.google.sk',
    '*.google.com.sl',
    '*.google.sn',
    '*.google.so',
    '*.google.sm',
    '*.google.sr',
    '*.google.st',
    '*.google.com.sv',
    '*.google.td',
    '*.google.tg',
    '*.google.co.th',
    '*.google.com.tj',
    '*.google.tl',
    '*.google.tm',
    '*.google.tn',
    '*.google.to',
    '*.google.com.tr',
    '*.google.tt',
    '*.google.com.tw',
    '*.google.co.tz',
    '*.google.com.ua',
    '*.google.co.ug',
    '*.google.co.uk',
    '*.google.com.uy',
    '*.google.co.uz',
    '*.google.com.vc',
    '*.google.co.ve',
    '*.google.co.vi',
    '*.google.com.vn',
    '*.google.vu',
    '*.google.ws',
    '*.google.rs',
    '*.google.co.za',
    '*.google.co.zm',
    '*.google.co.zw',
    '*.google.cat',
    '*.googlesyndication.com',
    'td.doubleclick.net',
    '*.cookiebot.com',
    'www.facebook.com',
    'ct.pinterest.com',
    'static.zdassets.com',
    '*.hotjar.com',
    '*.tracify.ai',
    '*.attn.tv',
  ],
  'object-src': ["'self'"],
})
  .map(([src, val]) => `${src} ${val.join(' ')}`)
  .join('; ')

export const consultingPartnerLink = {
  EU: 'https://www.google.com/maps/d/u/0/viewer?mid=1WhRRbH9zZDJZIXNzu-FnVX7FYgSztoo&ll=50.448617249585574%2C11.576339153318767&z=6',
  UK: 'https://www.google.com/maps/d/u/0/viewer?mid=1WhRRbH9zZDJZIXNzu-FnVX7FYgSztoo&ll=54.53496656109721%2C-3.001845816319339&z=6',
  CH: 'https://www.google.com/maps/d/u/0/viewer?mid=1WhRRbH9zZDJZIXNzu-FnVX7FYgSztoo&ll=47.05011696286064%2C8.777404558777665&z=8',
} as Record<string, string>

export const consultingLocations = {
  DE: {
    consultation: {
      price: 40,
      currency: 'EUR',
      booking:
        'https://book.timify.com/services?accountId=66d83be3c48ca819549ce048&hideCloseButton=true'
    },
    weekdays: 'Mo - Fr',
    hours: [
      ['09:00', '18:00'],
    ],
    city: 'Köln',
    mapsLink:
      'https://www.google.de/maps/place/bellicon+Europe+GmbH/@50.9665127,7.0105998,17z/data=!3m2!4b1!5s0x47bf2603ab842d03:0x7ca9ec371b45c1b7!4m6!3m5!1s0x47bedf67c8bff375:0xa39cabed62762f64!8m2!3d50.9665127!4d7.0131747!16s%2Fg%2F1thtnqr6?entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D'
  },
  EN: {
    consultation: {
      price: 40,
      currency: 'EUR',
      booking:
        'https://book.timify.com/services?accountId=66d83be3c48ca819549ce048&hideCloseButton=true'
    },
    weekdays: 'Mo - Fr',
    hours: [
      ['09:00', '18:00'],
    ],
    city: 'Cologne',
    mapsLink:
      'https://www.google.de/maps/place/bellicon+Europe+GmbH/@50.9665127,7.0105998,17z/data=!3m2!4b1!5s0x47bf2603ab842d03:0x7ca9ec371b45c1b7!4m6!3m5!1s0x47bedf67c8bff375:0xa39cabed62762f64!8m2!3d50.9665127!4d7.0131747!16s%2Fg%2F1thtnqr6?entry=ttu&g_ep=EgoyMDI0MDkxMC4wIKXMDSoASAFQAw%3D%3D'
  },
  CH: {
    consultation: {
      price: 50,
      currency: 'CHF',
      booking:
        'https://book.timify.com/services?accountId=5eb27de05a00871a530cd8f6&hideCloseButton=true',
    },
    weekdays: 'Mo - Fr',
    hours: [
      ['08:30', '12:00'],
      ['13:00', '17:00'],
    ],
    city: 'Luzern',
    mapsLink:
      'https://www.google.de/maps/place/bellicon+Schweiz+AG/@47.0866784,8.2516397,17z/data=!3m1!4b1!4m5!3m4!1s0x4790120ff4f15e93:0x2b8241437d0f6d32!8m2!3d47.0866784!4d8.2538284',
  },
}

export default {
  regions: [
    {
      id: 'CH',
      currency: 'CHF',
      defaultLanguage: 'de',
      languages: ['en', 'de', 'fr'],
      units: 'metric',
      payments: [
        'visa',
        'mastercard',
        'applepay',
        'twint',
        'giftcard',
        'invoice',
      ],
      shipping: {
        countries: [{ code: 'CH', zone: 'Switzerland' }],
        costs: {
          default: [
            {
              group: [0, 99.99],
              values: [
                { zone: 'CH, LI', price: 10 }
              ],
            },
            {
              group: 100,
              values: [
                { zone: 'CH, LI', price: 0 }
            ],
            },
          ],
        },
      },
      customerSupport: {
        to: 'https://support.bellicon.com/hc/<LANGUAGE>/',
        phone: {
          de: 'https://support.bellicon.com/hc/de/sections/6716100734738-Kontakt',
          en: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
          fr: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
        },
      },
      platform: {
        monthly: {
          amount: 12.9,
          currency: 'CHF',
          freeDays: 7,
          interval: 'monthly',
          chargebeeSubscription: '2023-CHF-Monthly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-CHF-30D',
        },
        yearly: {
          amount: 99.0,
          currency: 'CHF',
          freeDays: 30,
          interval: 'yearly',
          chargebeeSubscription: '2023-CHF-Yearly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-CHF-Yearly',
        },
      },
      consulting: consultingLocations.CH,
    },
    {
      id: 'EU',
      currency: 'EUR',
      defaultLanguage: 'de',
      units: 'metric',
      languages: ['en', 'de', 'fr', 'nl'],
      payments: [
        'visa',
        'mastercard',
        'americanexpress',
        'maestro',
        'applepay',
        'googlepay',
        'shoppay',
        'klarna',
        'klarnasofort',
        'paypal',
        'ideal',
        'giftcard',
        'vorkasse',
      ],
      shipping: {
        countries: [
          { code: 'AT', zone: 'EU' },
          { code: 'BE', zone: 'EU' },
          { code: 'BG', zone: 'EU' },
          { code: 'CZ', zone: 'EU' },
          { code: 'DE', zone: 'GER' },
          { code: 'DK', zone: 'EU' },
          { code: 'EE', zone: 'EU' },
          { code: 'ES', zone: 'EU' },
          { code: 'FI', zone: 'EU' },
          { code: 'FR', zone: 'EU' },
          { code: 'GR', zone: 'EU' },
          { code: 'HR', zone: 'EU' },
          { code: 'HU', zone: 'EU' },
          { code: 'IE', zone: 'EU' },
          { code: 'IT', zone: 'EU' },
          { code: 'LT', zone: 'EU' },
          { code: 'LU', zone: 'EU' },
          { code: 'LV', zone: 'EU' },
          { code: 'NL', zone: 'EU' },
          { code: 'NO', zone: 'EU' },
          { code: 'PL', zone: 'EU' },
          { code: 'PT', zone: 'EU' },
          { code: 'RO', zone: 'EU' },
          { code: 'SE', zone: 'EU' },
          { code: 'SI', zone: 'EU' },
          { code: 'SK', zone: 'EU' },
        ],
        costs: {
          default: [
            {
              group: [0, 98.99],
              values: [
                { zone: 'GER', price: 4 },
                { zone: 'EU', price: 6 }
              ],
            },
            {
              group: [99, 199.99],
              values: [
                { zone: 'GER', price: 8 },
                { zone: 'EU', price: 18 }
              ],
            },
            {
              group: 200,
              values: [
                { zone: 'GER', price: 0 },
                { zone: 'EU', price: 0 }
              ],
            },
          ],
        },
      },
      customerSupport: {
        to: 'https://support.bellicon.com/hc/<LANGUAGE>/',
        phone: {
          de: 'https://support.bellicon.com/hc/de/sections/6716100734738-Kontakt',
          en: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
          fr: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
          nl: 'https://support.bellicon.com/hc/nl/sections/6716100734738-Contact',
        },
      },
      platform: {
        monthly: {
          amount: 12.9,
          currency: 'EUR',
          freeDays: 7,
          interval: 'monthly',
          chargebeeSubscription: '2023-EUR-Monthly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-EUR-30D',
        },
        yearly: {
          amount: 99.0,
          currency: 'EUR',
          freeDays: 30,
          interval: 'yearly',
          chargebeeSubscription: '2023-EUR-Yearly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-EUR-Yearly',
        },
      },
      consulting: consultingLocations.DE,
    },
    {
      id: 'US',
      currency: 'USD',
      defaultLanguage: 'en',
      languages: ['en'],
      units: 'imperial',
      payments: [
        'visa',
        'mastercard',
        'americanexpress',
        'discover',
        'dinersclub',
        'applepay',
        'googlepay',
        'shoppay',
        'paypal',
        'check',
      ],
      shipping: {
        countries: [{ code: 'US', zone: 'USA' }],
        costs: {
          default: [
            {
              group: [0, 19.99],
              values: [
                { zone: 'USA', price: 8 },
                { zone: 'Canada', price: 25 },
                { zone: 'World', price: 45 },
              ],
            },
            {
              group: [20, 114.99],
              values: [
                { zone: 'USA', price: 15 },
                { zone: 'Canada', price: 35 },
                { zone: 'World', price: 45 },
              ],
            },
            {
              group: [115, 299.99],
              values: [
                { zone: 'USA', price: 30 },
                { zone: 'Canada', price: 70 },
                { zone: 'World', price: 70 },
              ],
            },
            {
              group: 300,
              values: [
                { zone: 'USA', price: 0 },
                { zone: 'Canada', price: 0 },
                { zone: 'World (unassemb.)', price: 0 },
                { zone: 'World (assemb.)', price: 199 },
              ],
            },
          ],
        },
      },
      customerSupport: {
        to: 'https://support.bellicon.com/hc/en-us/',
        phone: {
          en: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
        },
      },
      platform: {
        monthly: {
          amount: 12.9,
          currency: 'USD',
          freeDays: 7,
          interval: 'monthly',
          chargebeeSubscription: '2023-USD-Monthly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-USD-30D',
        },
        yearly: {
          amount: 99.0,
          currency: 'USD',
          freeDays: 30,
          interval: 'yearly',
          chargebeeSubscription: '2023-USD-Yearly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-USD-Yearly',
        },
      },
      consulting: consultingLocations.DE,
    },
    {
      id: 'UK',
      currency: 'GBP',
      defaultLanguage: 'en',
      languages: ['en'],
      units: 'metric',
      payments: [
        'visa',
        'mastercard',
        'americanexpress',
        'maestro',
        'applepay',
        'googlepay',
        'shoppay',
        'klarna',
        'paypal',
        'giftcard',
      ],
      shipping: {
        countries: [{ code: 'GB', zone: 'UK' }],
        costs: {
          default: [
            {
              group: [0, 98.99],
              values: [
                { zone: 'UK', price: 6 }
              ],
            },
            {
              group: [99, 199.99],
              values: [
                { zone: 'UK', price: 18 }
              ],
            },
            {
              group: 200,
              values: [
                { zone: 'UK', price: 0 }
              ],
            },
          ],
        },
      },
      customerSupport: {
        to: 'https://support.bellicon.com/hc/<LANGUAGE>/',
        phone: {
          en: 'https://support.bellicon.com/hc/en-us/sections/6716100734738-Contact',
        },
      },
      platform: {
        monthly: {
          amount: 12.9,
          currency: 'GBP',
          freeDays: 7,
          interval: 'monthly',
          chargebeeSubscription: '2023-GBP-Monthly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-GBP-30D',
        },
        yearly: {
          amount: 99.0,
          currency: 'GBP',
          freeDays: 30,
          interval: 'yearly',
          chargebeeSubscription: '2023-GBP-Yearly',
          chargebeeSubscriptionCoupon: '20230602-premium-coupon-GBP-Yearly',
        },
      },
      consulting: consultingLocations.DE,
    },
  ] as Region[],
  defaultRegion: 'US',
  // default locale for home
  defaultLocale: 'en-US',
  // languages available for video platform
  availableVideoLanguages: ['de', 'en'] as const,
  // specific locale to hreflang transformation rules
  hrefLangMap: {
    'de-EU': 'de',
    'en-EU': 'en',
    'fr-EU': 'fr',
    'nl-EU': 'nl',
    'en-UK': 'en-gb',
  } as Record<string, string>,
  // Includes locale-less versions of the following pages in the app
  localeLessPageWhitelist: [
    '/',
    // e.g. '/login-service/',
  ],
  // Excludes matching pages (represented as full paths) from being generated in the corresponding locale
  urlIsoBlacklist: [
    { page: 'consulting', iso: 'en-US' },
    { page: 'magazine/index', iso: 'nl-EU' },
    // TODO: refactor to ensure whitelist (generate following pages for en-US only)
    { page: 'safety-instructions', iso: 'de-CH' },
    { page: 'safety-instructions', iso: 'de-EU' },
    { page: 'safety-instructions', iso: 'en-CH' },
    { page: 'safety-instructions', iso: 'en-EU' },
    { page: 'safety-instructions', iso: 'en-UK' },
    { page: 'safety-instructions', iso: 'fr-CH' },
    { page: 'safety-instructions', iso: 'fr-EU' },
    { page: 'safety-instructions', iso: 'nl-EU' },
    { page: 'disclaimer', iso: 'de-CH' },
    { page: 'disclaimer', iso: 'de-EU' },
    { page: 'disclaimer', iso: 'en-CH' },
    { page: 'disclaimer', iso: 'en-EU' },
    { page: 'disclaimer', iso: 'en-UK' },
    { page: 'disclaimer', iso: 'fr-CH' },
    { page: 'disclaimer', iso: 'fr-EU' },
    { page: 'disclaimer', iso: 'nl-EU' },
  ],
  // excludes pages from sitemap and adds robots meta tags to page
  seoUrlBlacklist: [
    'cart',
    'configurator',
    'configurator-equipment',
    'auth-sign-in',
    'auth-sign-up',
  ],
  // collections for product detail pages
  shopCollections: [
    { handle: 'equipment-trampoline' },
    { handle: 'equipment-training' },
    { handle: 'equipment-spares' },
    { handle: 'gifts' },
    { handle: 'special', unlisted: true },
  ],
  bestsellerCollectionHandle: 'bestseller',
  // allowed page builder components
  contentSectionWhitelist: [
    'heading',
    'image',
    'video',
    'text',
    'list',
    'hint',
    'quote',
    'separator',
    'sales-teaser',
    'carousel',
    'cta-banner',
    'feature-image',
    'image-text',
    'product-teaser',
  ],
  configurator: {
    // shopify collection handle for configurator products
    collection: 'configurator',
    // number of configurator products in shopify
    numProducts: 38,
    // number of variants per configurator product
    numProductVariants: 10,
    // training info
    numFreeTrainingDays: 30,
    numVoucherTrainingMonths: 12,
  },
  // max delivery days defaults
  productDeliveryDaysFallback: 7,
  // SKUs for bellicon+ products
  bHomeHandle: 'gift-bhome-universal',
  bHomeDiscountedHandle: 'gift-bhome-bundle',

  // SKU and Handle for configurator assembly step
  assemblyHandle: 'assembly',
  assemblySKU: 'ASSEMBLY',

  // locales for which ZenDesk is available
  zendeskLocales: [
    'en-US',
    'de-EU',
    'en-EU',
    'fr-EU',
    'nl-EU',
    'en-UK',
    'de-CH',
    'en-CH',
    'fr-CH',
  ],
  // social media platforms per locale
  customerSupport: {
    deleteAccount: {
      de: 'https://support.bellicon.com/hc/de/articles/10781621362066',
      en: 'https://support.bellicon.com/hc/en-gb/articles/10781621362066',
    },
  },
  socialMedia: {
    de: {
      instagram: 'https://www.instagram.com/bellicon_de/',
      facebook: 'https://www.facebook.com/bellicon.Deutschland/',
      youtube: 'https://www.youtube.com/c/belliconGmbH/',
      tiktok: 'https://www.tiktok.com/@bellicon_',
    },
    en: {
      instagram: 'https://www.instagram.com/bellicon/',
      facebook: 'https://www.facebook.com/bellicon.USA/',
      youtube: 'https://www.youtube.com/c/belliconusa',
      tiktok: 'https://www.tiktok.com/@bellicon_',
    },
    fr: {
      instagram: 'https://www.instagram.com/bellicon/',
      facebook: 'https://www.facebook.com/bellicon.USA/',
      youtube: 'https://www.youtube.com/c/belliconusa',
      tiktok: 'https://www.tiktok.com/@bellicon_',
    },
    nl: {
      instagram: 'https://www.instagram.com/bellicon/',
      facebook: 'https://www.facebook.com/bellicon.USA/',
      youtube: 'https://www.youtube.com/c/belliconusa',
      tiktok: 'https://www.tiktok.com/@bellicon_',
    },
    'en-US': {
      instagram: 'https://www.instagram.com/bellicon/',
      facebook: 'https://www.facebook.com/bellicon.USA/',
      youtube: 'https://www.youtube.com/c/belliconusa',
      tiktok: 'https://www.tiktok.com/@bellicon_',
    },
  },
  tracking: {
    cookieBot: {
      id: 'f5663138-4e58-468e-8f02-e84168bbccd0',
    },
    googleTagManager: {
      id: 'GTM-PS6X6SK',
    },
  },
  trustedShops: {
    CH: 'XFEED4E3D5BE1BC930192CCB9BC8BC723',
    EU: 'XA6310F9FB0D6329BA9BE1D367EEB43D9',
  },
  tracify: {
    CH: '1fd1dee8-bf42-4834-9576-467653532895',
    EU: '4a739bf8-ed84-4ab4-8813-1cdf7b74918e',
    UK: '86ee8437-fcf2-497c-a77e-904fc9a9537e',
    US: '68ecd37c-d930-439e-b5a6-4dd9bb11e034',
  },
  cookies: {
    userLocale: { name: 'user-locale', lifetimeDays: 10 * 365 },
    videoLanguage: { name: 'video-locale', lifetimeDays: 10 * 365 },
    cart: { name: 'cart', lifetimeDays: 0.0625 },
    authToken: { name: 'auth-token', lifetimeDays: 3 },
    authRefresh: { name: 'auth-refresh', lifetimeDays: 3 },
    pkceVerifier: { name: 'pkce-verifier', lifetimeDays: 1 / 24 },
    partner: { name: 'partner', lifetimeDays: 1 },
    secure: process.env.ENVIRONMENT !== 'development',
  },
  fusionAuth: {
    applicationId:
      process.env.ENVIRONMENT === 'production'
        ? '02951f9a-bb84-407e-9d57-d05e7c97dc2b'
        : '7629b408-3393-4217-9281-35a37558b2ee',
    redirectUri:
      process.env.NODE_ENV === 'development'
        ? `${process.env.DOMAIN}/auth/callback/`
        : `${process.env.DOMAIN}/auth/callback/`,
    google: {
      identityProviderId: '82339786-3dff-42a6-aac6-1f1ceecb6c46',
    },
    facebook: {
      identityProviderId: '56abdcc7-8bd9-4321-9621-4e9bbebae494',
    },
    apple: {
      identityProviderId: '13d2a5db-7ef9-4d62-b909-0df58612e775',
    },
  },
  activeFeatureToggles: ['video'],
}
